import React from 'react';
import { Heading, ExternalLink } from '../../components';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';
const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionTextMaybe = props => {
  const { text, heading, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  const urlRegex = /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

  return text ? (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}

      <p className={textClass}>
        {text.match(urlRegex) == text ? (
  
          <ExternalLink href={text} className="" target="_blank">
            {richText(text.split('/').slice(-1)[0], {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </ExternalLink>
        ) : (

          heading == "Lichess Account" ? (
            <ExternalLink href={'https://lichess.org/@/'+text} className="" target="_blank">
              {richText(text, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </ExternalLink>

          ) : heading == "Chess.com Account" ? (
            <ExternalLink href={'https://www.chess.com/member/'+text} className="" target="_blank">
              {richText(text, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </ExternalLink>
          ) : richText(text, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })

        )}
      </p>


    </div>
  ) : null;
};

export default SectionTextMaybe;
